.navbar {
  padding: 40px 0px;
}

.nav-link {
  color: var(--color-text);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin: 0.4rem;
}

.nav-link:last-child {
  border: 1px solid;
  border-radius: 5px;
  margin-left: 10px;
}

.nav-link:hover {
  background: var(--color-highlight);
  border-radius: 5px;
  color: var(--color-text);
}

.nav-link:last-child:hover {
  background: var(--color-highlight);
  color: var(--color-text);
}

@media (max-width: 991px) {
  .navbar-collapse,
  .navbar-nav {
    text-align: right;
  }
  .heading-fix {
    font-size: 38px !important;
  }

  .nav-link {
    margin: 0.1rem 0;
    padding: 0.2rem;
  }

  .nav-link:last-child {
    background: var(--color-highlight);
    color: var(--color-text);
    text-align: center;
  }
}

.navbar-toggler {
  border: none;
  padding-top: 0.8rem;
  text-align: center;
  margin-bottom: 1rem;
}
.navbar-toggler-icon {
  margin-top: 10px;
  background: url(../../assets/menu.png) no-repeat;
}
