.parent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.parent img {
  padding-bottom: 35px;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
