.logos {
  overflow: hidden;
  padding: 10px 0px;
  background: rgba(26, 181, 219, 1) 93%;
  white-space: nowrap;
  position: relative;
}
.logos:hover .logos-slider {
  animation-play-state: paused;
}

.logos::before,
.logos::after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}
.logos::before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}
.logos::after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.logos-slider {
  display: inline-block;
  animation: 35s slide infinite linear;
}
.logos-slider span {
  font-size: 40px;
  font-weight: 600;
  margin: 0 20px;
}
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media screen and (max-width: 768px) {
  .logos::before,
  .logos::after {
    width: 80px;
  }
  .logos-slider span {
    font-size: 26px;
  }
  .parent {
    padding-top: 10px;
  }
}
