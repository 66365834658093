.why-choose-us {
  width: 100%;
  font-size: 18px;
}
.why-choose-us span {
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
}

.why-choose-us th,
.why-choose-us td {
  padding: 8px;
  text-align: center;
}

.why-choose-us th {
  background-color: #f2f2f2;
}

.why-choose-us td ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.why-choose-us td ul li {
  margin-bottom: 5px;
  height: 60px; /* Setting equal height */
  display: flex;
  align-items: center;
}

.why-choose-us td ul li span {
  margin-right: 5px;
}
@media (max-width: 767px) {
  .why {
    display: none;
  }
}
